/* https://www.browserstack.com/guide/create-browser-specific-css */

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  display: flex;
  flex-direction: column;
  height: 100%;
  font-display: swap;
  margin: 0;
  overflow: hidden;
  /* height: 100%; */
  width: 100%;
  /* margin: 0; */
  padding: 0;
}

::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
  /* IE and Edge */
}

.scroll-hide {
  scrollbar-width: none;
  /* Firefox */
}

body {
  font-family: 'Lato';
  caret-color: #ff5b91;
  background-image: url('/assets/svg/background.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

* {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

*:focus {
  outline: none !important;
}

/* span{
   font-family: "Lato" !important;
} */

/* button{
  font-family: "Lato" !important;
} */

#__next {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.height-webkit-fill-available {
  height: -webkit-fill-available;
}

input {
  user-select: text;
}

/* *:focus{
  outline-width: 0.5px;
  outline-color: #ff5b91;
} */

@font-face {
  font-family: 'Lato';
  src: url('/fonts/Lato.ttf') format('truetype');
}

@font-face {
  font-family: 'Century Gothic';
  src: url('/fonts/CenturyGothic.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Century Gothic';
  src: url('/fonts/CenturyGothic-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: bold;
}

.height-stretch {
  height: 100%;
  height: -moz-available;
  height: -webkit-fill-available;
}

::-moz-selection {
  /* Code for Firefox */
  color: #fff;
  background: #ff5b91;
}

::selection {
  color: #fff;
  background: #ff5b91;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Century Gothic';
  font-weight: 700;
  letter-spacing: 0.1px;
  /* line-height: unset !important; */
}

@layer base {
  h1 {
    @apply text-5xl;
  }

  h2 {
    @apply text-4xl;
  }

  h3 {
    @apply text-3xl;
  }

  h4 {
    @apply text-2xl;
  }

  h5 {
    @apply text-xl;
  }

  h6 {
    @apply text-lg;
  }
}

.react-modal-sheet-container {
  max-width: 400px;
  margin: 0 calc(50% - 200px) !important;
  border-radius: 1.5rem;
  margin-bottom: 48px !important;
  height: auto !important;
  max-height: 80 vh !important;
  overflow-y: auto;
}

@media screen and (max-width: 550px) {
  .react-modal-sheet-container {
    width: 100% !important;
    max-width: 100% !important;
    border-radius: 0;
    margin: 0 !important;
  }
}

@layer components {
  .nextImage > span {
    @apply rounded-2xl;
  }

  .badgeWrapper > span {
    @apply shadow rounded-xl;
  }

  .lottieAnimationWrapper > div {
    height: 550px !important;
  }

  .center {
    @apply flex items-center justify-center;
  }

  * {
    outline-color: #fc8eac;
  }

  .button {
    @apply px-2 py-1 transition-all bg-pink-500 rounded-sm cursor-pointer focus:outline-1 hover:bg-opacity-20;
  }
}

.gradient-transparent-bg {
  /* background: linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.704245) 100%); */
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 0%, #000000 100%);
}

.gradient-white-transparent-bg {
  background: linear-gradient(360deg, #ffffff 14.25%, rgba(251, 251, 251, 0) 100%);
}

/* //! reverse the z index for the card animations */

#layout > div:nth-child(1) {
  z-index: 10;
}

#layout > div:nth-child(2) {
  z-index: 9;
}

#layout > div:nth-child(3) {
  z-index: 8;
}

#stats > div:nth-child(1) {
  background-color: #ff5b91;
}

#stats > div:nth-child(2) {
  background-color: #a869c5;
}

/* carousel on landing page */
/* .slick-slide > div {
  margin: 0 0px;
}
.slick-list {
  margin: 0 -0px;
} */
.slick-slider {
  overflow: hidden;
}

.slick-dots {
  position: static !important;
  margin: 10px 0 !important;
}

.slick-dots li button:before {
  font-size: 14px !important;
}

.slick-dots .slick-active button:before {
  color: #ff5b91 !important;
  font-size: 20px !important;
}

.slick-slide > div:first-child {
  margin-left: 15px !important;
}

@media screen and (max-width: 400px) {
  .slick-slide > div:first-child {
    margin-left: 10px !important;
  }
}

.slick-dots li {
  margin: 0 1px !important;
}

.calender-year::-webkit-scrollbar {
  background-color: #ff5b91;
  width: 6px;
}

.stepCombine {
  scrollbar-width: none;
}

.stepCombine::-webkit-scrollbar {
  display: none;
}

.word-spacing {
  word-spacing: 6px;
}

.blur {
  filter: blur(0.7px);
}

[data-rsbs-overlay],
[data-rsbs-backdrop],
[data-rsbs-root]:after {
  z-index: 999 !important;
}

@media screen and (min-width: 550px) {
  [data-rsbs-backdrop] {
    width: 377px;
    left: 50% !important;
    transform: translateX(-50%);
  }

  [data-rsbs-overlay] {
    margin: auto !important;
    left: 0;
    right: 0;
    width: 377px;
  }
}

.boxShadow-global {
  box-shadow: 0px 2px 6px rgba(203, 203, 203, 0.5);
}

/* TODO : remove pixels */
.Collapsible {
  background: #ffffff;
  border: 1px solid #f5f5f5;
  box-shadow: -2px 2px 12px #f5f5f5;
  border-radius: 8px;
  padding: 12px 14px 16px;
  margin: 12px 0;
}

.myelement {
  animation: fade-out;
  border: 1px solid red;
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.anim-mode-forward {
  animation-fill-mode: forwards !important;
}

[data-rsbs-overlay],
[data-rsbs-backdrop],
[data-rsbs-root]:after {
  z-index: 999 !important;
}

/* rc-slider css */

.rc-slider {
  position: relative;
  width: 100%;
  height: 14px;
  padding: 5px 0;
  border-radius: 6px;
  touch-action: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-slider * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-slider-rail {
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #e9e9e9;
  border-radius: 6px;
}

.rc-slider-track {
  position: absolute;
  height: 4px;
  background-color: #f96495f1;
  border-radius: 6px;
  transition: 0.3s ease !important;
}

.rc-slider-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  margin-top: -8px;
  background-color: #ffffff;
  border: solid 1px #ffffff;
  border-radius: 50%;
  cursor: pointer;
  cursor: -webkit-grab;
  cursor: grab;
  opacity: 0.8;
  touch-action: pan-x;
  box-shadow: 0px 2px 6px #d9d9d9;
  opacity: 1;
  transition: 0.3s ease !important;
}

.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  border-color: #ffffff;
  box-shadow: 0 0 0 5px #fbd5e2f1;
}

.rc-slider-handle:focus {
  outline: none;
  box-shadow: 0px 2px 6px #d9d9d9;
}

.rc-slider-handle-click-focused:focus {
  border-color: #ffffff;
  box-shadow: unset;
}

.rc-slider-handle:hover {
  border-color: #ffffff;
}

.rc-slider-handle:active {
  border-color: #ffffff;
  box-shadow: 0 0 5px #fbd5e2f1;
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ------------ rc select ---------- */

.rc-select {
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  color: #666;
  overflow: auto;
  line-height: 28px;
}

.rc-select-selector {
  border: 1px solid #ff5b91;
  padding: 0.1rem 0.4rem;
  font-size: 0.8rem;
}

.rc-virtual-list-holder {
  padding: 0.5rem;
  font-size: 0.8rem;
}

.rc-select-allow-clear .rc-select-selection--single .rc-select-selection__rendered {
  padding-right: 40px;
}

.rc-select-selection-search {
  display: none;
}

.rc-select-item {
  margin: 0.3rem 0;
}

.rc-virtual-list-holder-inner {
  padding-bottom: 0.8rem;
}

.rc-select ul,
.rc-select li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.rc-select > ul > li > a {
  padding: 0;
  background-color: #fff;
}

.rc-select-arrow {
  position: absolute;
  z-index: 999;
  top: 0px;
  right: 6px;
  outline: none;
  display: flex;
  align-items: center;
  height: 100%;
}

.rc-select-selection {
  outline: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
  box-sizing: border-box;
  display: block;
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #d9d9d9;
}

.rc-select-item-option-selected {
  display: flex;
  align-items: center;
  color: #ff5b91;
}

.rc-select-selection__placeholder {
  position: absolute;
  top: 0;
  color: #aaa;
}

.rc-select-selection__clear {
  font-weight: bold;
  position: absolute;
  line-height: 28px;
}

.rc-select-selection__clear:after {
  content: '\D7';
}

.rc-select-focused .rc-select-selection {
  border-color: #23c0fa;
  box-shadow: 0 0 2px rgba(45, 183, 245, 0.8);
}

.rc-select-enabled .rc-select-selection:hover {
  border-color: #23c0fa;
  box-shadow: 0 0 2px rgba(45, 183, 245, 0.8);
}

.rc-select-enabled .rc-select-selection:active {
  border-color: #2db7f5;
}

.rc-select-selection--single {
  height: 28px;
  line-height: 28px;
  cursor: pointer;
  position: relative;
}

.rc-select-selection--single .rc-select-selection-selected-value {
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 0;
}

.rc-select-selection--single .rc-select-selection__rendered {
  height: 28px;
  position: relative;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 10px;
  padding-right: 20px;
  line-height: 28px;
}

.rc-select-selection--single .rc-select-selection__clear {
  top: 0;
  right: 20px;
}

.rc-select-disabled {
  color: #ccc;
  cursor: not-allowed;
}

.rc-select-disabled .rc-select-selection--single,
.rc-select-disabled .rc-select-selection__choice__remove {
  cursor: not-allowed;
  color: #ccc;
}

.rc-select-disabled .rc-select-selection--single:hover,
.rc-select-disabled .rc-select-selection__choice__remove:hover {
  cursor: not-allowed;
  color: #ccc;
}

.rc-select-search__field__wrap {
  display: inline-block;
}

.rc-select-search__field__placeholder {
  position: absolute;
  top: 0;
  left: 3px;
  color: #aaa;
}

.rc-select-search--inline {
  width: 100%;
}

.rc-select-search--inline .rc-select-search__field__wrap {
  width: 100%;
}

.rc-select-search--inline .rc-select-search__field {
  border: none;
  font-size: 100%;
  background: transparent;
  outline: 0;
  width: 100%;
}

.rc-select-search--inline .rc-select-search__field::-ms-clear {
  display: none;
}

.rc-select-search--inline .rc-select-search__field__mirror {
  position: absolute;
  top: -999px;
  left: 0;
  white-space: pre;
}

.rc-select-search--inline > i {
  float: right;
}

.rc-select-enabled .rc-select-selection__choice {
  cursor: default;
}

.rc-select-enabled .rc-select-selection__choice:hover .rc-select-selection__choice__remove {
  opacity: 1;
  transform: scale(1);
}

.rc-select-enabled .rc-select-selection__choice:hover .rc-select-selection__choice__content {
  margin-left: -8px;
  margin-right: 8px;
}

.rc-select-enabled .rc-select-selection__choice__disabled {
  cursor: not-allowed;
}

.rc-select-enabled
  .rc-select-selection__choice__disabled:hover
  .rc-select-selection__choice__content {
  margin-left: 0;
  margin-right: 0;
}

.rc-select .rc-select-selection__choice {
  background-color: #f3f3f3;
  border-radius: 4px;
  float: left;
  padding: 0 15px;
  margin-right: 4px;
  position: relative;
  overflow: hidden;
  transition: padding 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045),
    width 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.rc-select .rc-select-selection__choice__content {
  margin-left: 0;
  margin-right: 0;
  transition: margin 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.rc-select .rc-select-selection__choice-zoom-enter,
.rc-select .rc-select-selection__choice-zoom-appear,
.rc-select .rc-select-selection__choice-zoom-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  transform-origin: 0 0;
  opacity: 0;
  animation-play-state: paused;
  animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.rc-select .rc-select-selection__choice-zoom-leave {
  opacity: 1;
  animation-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.rc-select .rc-select-selection__choice-zoom-enter.rc-select-selection__choice-zoom-enter-active,
.rc-select .rc-select-selection__choice-zoom-appear.rc-select-selection__choice-zoom-appear-active {
  animation-play-state: running;
  animation-name: rcSelectChoiceZoomIn;
}

.rc-select .rc-select-selection__choice-zoom-leave.rc-select-selection__choice-zoom-leave-active {
  animation-play-state: running;
  animation-name: rcSelectChoiceZoomOut;
}

@keyframes rcSelectChoiceZoomIn {
  0% {
    transform: scale(0.6);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes rcSelectChoiceZoomOut {
  to {
    transform: scale(0);
    opacity: 0;
  }
}

.rc-select .rc-select-selection__choice__remove {
  color: #919191;
  cursor: pointer;
  font-weight: bold;
  padding: 0 0 0 8px;
  position: absolute;
  opacity: 0;
  transform: scale(0);
  top: 0;
  right: 2px;
  transition: opacity 0.3s, transform 0.3s;
}

.rc-select .rc-select-selection__choice__remove:before {
  content: '\D7';
}

.rc-select .rc-select-selection__choice__remove:hover {
  color: #333;
}

.rc-select-dropdown {
  background-color: white;
  border: 1px solid #d9d9d9;
  box-shadow: 0 0px 4px #d9d9d9;
  border-radius: 4px;
  box-sizing: border-box;
  z-index: 100;
  left: -9999px;
  top: -9999px;
  position: absolute;
  outline: none;
}

.rc-select-dropdown:empty,
.rc-select-dropdown-hidden {
  display: none;
}

.rc-select-dropdown-menu {
  outline: none;
  margin: 0;
  padding: 0;
  list-style: none;
  z-index: 9999;
}

.rc-select-dropdown-menu > li {
  margin: 0;
  padding: 0;
}

.rc-select-dropdown-menu-item-group-list {
  margin: 0;
  padding: 0;
}

.rc-select-dropdown-menu-item-group-list > li.rc-select-menu-item {
  padding-left: 20px;
}

.rc-select-dropdown-menu-item-group-title {
  color: #999;
  line-height: 1.5;
  padding: 8px 10px;
  border-bottom: 1px solid #dedede;
}

li.rc-select-dropdown-menu-item {
  margin: 0;
  position: relative;
  display: block;
  padding: 7px 10px;
  font-weight: normal;
  color: #666;
  white-space: nowrap;
}

li.rc-select-dropdown-menu-item-disabled {
  color: #ccc;
  cursor: not-allowed;
}

li.rc-select-dropdown-menu-item-selected {
  color: #666;
  background-color: #ddd;
  display: flex;
}

li.rc-select-dropdown-menu-item-active {
  background-color: #5897fb;
  color: white;
  cursor: pointer;
}

li.rc-select-dropdown-menu-item-divider {
  height: 1px;
  margin: 1px 0;
  overflow: hidden;
  background-color: #e5e5e5;
  line-height: 0;
}

.rc-select-dropdown-slide-up-enter,
.rc-select-dropdown-slide-up-appear {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  transform-origin: 0 0;
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-play-state: paused;
}

.rc-select-dropdown-slide-up-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  transform-origin: 0 0;
  opacity: 1;
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  animation-play-state: paused;
}

.rc-select-dropdown-slide-up-enter.rc-select-dropdown-slide-up-enter-active.rc-select-dropdown-placement-bottomLeft,
.rc-select-dropdown-slide-up-appear.rc-select-dropdown-slide-up-appear-active.rc-select-dropdown-placement-bottomLeft {
  animation-name: rcSelectDropdownSlideUpIn;
  animation-play-state: running;
}

.rc-select-dropdown-slide-up-leave.rc-select-dropdown-slide-up-leave-active.rc-select-dropdown-placement-bottomLeft {
  animation-name: rcSelectDropdownSlideUpOut;
  animation-play-state: running;
}

.rc-select-dropdown-slide-up-enter.rc-select-dropdown-slide-up-enter-active.rc-select-dropdown-placement-topLeft,
.rc-select-dropdown-slide-up-appear.rc-select-dropdown-slide-up-appear-active.rc-select-dropdown-placement-topLeft {
  animation-name: rcSelectDropdownSlideDownIn;
  animation-play-state: running;
}

.rc-select-dropdown-slide-up-leave.rc-select-dropdown-slide-up-leave-active.rc-select-dropdown-placement-topLeft {
  animation-name: rcSelectDropdownSlideDownOut;
  animation-play-state: running;
}

@keyframes rcSelectDropdownSlideUpIn {
  0% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleY(0);
  }

  100% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleY(1);
  }
}

@keyframes rcSelectDropdownSlideUpOut {
  0% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleY(1);
  }

  100% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleY(0);
  }
}

@keyframes rcSelectDropdownSlideDownIn {
  0% {
    opacity: 0;
    transform-origin: 0% 100%;
    transform: scaleY(0);
  }

  100% {
    opacity: 1;
    transform-origin: 0% 100%;
    transform: scaleY(1);
  }
}

@keyframes rcSelectDropdownSlideDownOut {
  0% {
    opacity: 1;
    transform-origin: 0% 100%;
    transform: scaleY(1);
  }

  100% {
    opacity: 0;
    transform-origin: 0% 100%;
    transform: scaleY(0);
  }
}

.rc-select-open .rc-select-arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

/* ------------ rc-select ends */

/* Skeleton */
.cardContainer {
  width: '100%';
  height: 150px;
  /* background-color: #ffff; */
  padding: 10px;
  border-radius: 8px;
}

.card-skeleton {
  background-image: linear-gradient(
    90deg,
    rgb(247, 247, 247) 0px,
    rgb(229 229 229 / 90%) 40px,
    rgb(239, 237, 237) 89px
  );
  /* background-image: linear-gradient(
    90deg,
    #ffe7be 0px,
    rgb(229 229 229 / 70%) 40px,
    #ffffff 89px
  ); */
  background-size: 300%;
  background-position: 100% 0;
  border-radius: inherit;
  animation: shimmer 1.5s infinite;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  height: 20px;
  margin-bottom: 15px;
}

.description {
  /* background-color: #23c0fa; */
  height: 140px;
}

@keyframes shimmer {
  to {
    background-position: -100% 0;
  }
}

/*-----react tool tip-----*/
.__react_component_tooltip.type-dark.place-top:after {
  background-color: #ff5b91 !important;
}

/* Loader css (Circle Loader) */

.lds-ring {
  display: inline-block;
  width: 8px;
  height: 8px;
}

.lds-ring div {
  top: 1px;
  right: 1px;
  position: absolute;
  width: 12px;
  height: 12px;
  /* margin: 1px; */
  border: 2px solid rgb(80, 189, 95);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: rgb(80, 189, 95) transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Sparkle Modal CSS*/

#sparkle-modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: all 0.3s ease-in-out;
  animation: fadein 0.5s linear 1 forwards;
  /* pointer-events: none; */
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

#sparkle-modal.enter-done {
  opacity: 1;
  pointer-events: visible;
}

#sparkle-modal.exit {
  opacity: 0;
}

.sparkle-modal-content {
  width: 90%;
  position: relative;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
  transform: scale(0.9);
  text-align: center;
  max-width: 535px;
  border-radius: 26px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 45px;
}

.sparkle-modal.enter-done .sparkle-modal-content {
  transform: scale(1);
}

.sparkle-modal.exit .sparkle-modal-content {
  transform: scale(1);
}

.sparkle-modal-header,
.sparkle-modal-footer {
  padding: 10px;
}

.sparkle-modal-title {
  margin: 0;
}

.sparkle-modal-body {
  padding: 10px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.profileContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 50px;
  /* background-color: blue; */
  border-radius: 26px;
}

.profilePhoto {
  border-radius: 50%;
  background-color: white;
  position: fixed;
  width: 120px;
  height: 120px;
  left: 50%;
  top: 0px;
  transform: translate(-50%, -50%);
  box-shadow: 0px 2px 7px rgb(175, 175, 175);
  padding: 2px;
  /* border: 2px solid gray; */
}

.text-box {
  /* max-width: 100%; */
  box-shadow: 0px 2px 7px rgb(175, 175, 175);

  width: 90%;
  /* margin-left: 17px; */
  height: auto;
  /* background-color: red; */
  border-radius: 26px;
}

.button {
  width: 150px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: yellow;
  border: none;
  box-shadow: 0px 2px 7px rgb(175, 175, 175);
  border-radius: 20px;
  padding: 10px;
}

.react-responsive-modal-modal {
  margin: 0px !important;
  padding-top: 0.5rem !important;
}

.zigzag {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: white;
}

.zigzag::before {
  position: absolute;
  top: -10px;
  width: 100%;
  height: 13px;
  background-image: linear-gradient(45deg, white 50%, transparent 50%),
    linear-gradient(-45deg, white 50%, transparent 50%);
  background-size: 13px 13px;
  content: '';
}

/* Premium  */

.parentDivBG {
  background: rgb(255, 246, 215) !important;
  background: linear-gradient(
    159deg,
    rgba(255, 246, 215, 1) 0%,
    rgba(255, 225, 181, 1) 100%
  ) !important;
}

.__react_component_tooltip.place-left::after {
  border-left: 12px solid #ff5b91 !important;
}

.__react_component_tooltip.place-right::after {
  border-right: 12px solid #ff5b91 !important;
}

.__react_component_tooltip.place-top::after {
  border-top: 12px solid #ff5b91 !important;
}

.__react_component_tooltip.place-bottom::after {
  border-bottom: 12px solid #ff5b91 !important;
}

/* Tooltip */

/* Custom properties */
:root {
  --tooltip-text-color: white;
  --tooltip-background-color: #ff5b91;
  --tooltip-margin: 0px;
  --tooltip-arrow-size: 6px;
}

/* Wrapping */
.Tooltip-Wrapper {
  display: inline-block;
  position: relative;
}

/* Absolute positioning */
.Tooltip-Tip {
  position: absolute;
  border-radius: 4px;
  left: 0;
  transform: translateX(-30%);
  padding: 6px;
  color: var(--tooltip-text-color);
  background: var(--tooltip-background-color);
  font-size: 14px;
  font-family: sans-serif;
  line-height: 1;
  z-index: 100;
  white-space: nowrap;
}

/* CSS border triangles */
.Tooltip-Tip::before {
  content: ' ';
  left: 0;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-width: var(--tooltip-arrow-size);
  margin-left: calc(var(--tooltip-arrow-size) * -1);
}

/* Absolute positioning */
.Tooltip-Tip.top {
  top: calc(var(--tooltip-margin) * -1);
}
/* CSS border triangles */
.Tooltip-Tip.top::before {
  top: 100%;
  border-top-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.Tooltip-Tip.right {
  left: calc(100% + var(--tooltip-margin));
  top: 50%;
  transform: translateX(0) translateY(-50%);
}
/* CSS border triangles */
.Tooltip-Tip.right::before {
  left: calc(var(--tooltip-arrow-size) * -1);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-right-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.Tooltip-Tip.bottom {
  bottom: calc(var(--tooltip-margin) * -1);
}
/* CSS border triangles */
.Tooltip-Tip.bottom::before {
  bottom: 100%;
  border-bottom-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.Tooltip-Tip.left {
  left: auto;
  right: calc(100% + var(--tooltip-margin));
  top: 50%;
  transform: translateX(0) translateY(-50%);
}
/* CSS border triangles */
.Tooltip-Tip.left::before {
  left: auto;
  right: calc(var(--tooltip-arrow-size) * -2);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-left-color: var(--tooltip-background-color);
}

/* Css for Dynamic Pricing */
.lato-bold {
  font-weight: bold;
  font-family: 'Lato';
}
